import '@/scss/global-styles.scss';

import(/* webpackChunkName: 'fonts' */ '@/scss/fonts.scss');
import(/* webpackChunkName: 'icons' */ '@/lib/fontawesome');
import('@/lib/lazysizes');

import('@/components/media-query-event');
import('@/components/main-nav');
import('@/components/slider');
import('@/components/image-swap');
import('@/components/cart/AddToCartInit');
import('@/components/isotope/isotope-grid');
import('@/components/contact-badge');
import('@/components/newsletter/newsletter-subscribe-unsubscribe');
import('@/components/ImageMap/ImageMapInit');
import('@/components/listing/listingInit');
import('@/components/search/initSearch');
import('@/components/modal/modal');
import('@/components/dealer-search/dealerSearchInit');

// test file for ms-dynamics-form styling
// import(/* webpackChunkName: 'ms-dynamics-form' */ '@/scss/tools/ms-dynamics-form.scss');

// pimcore editmode addon - please keep as last item :)
import('./editmode');
